module.exports = [{
      plugin: require('../../gatsby-plugin-ecookie/gatsby-browser.js'),
      options: {"plugins":[],"id":"4667b685b11d75dd5c700251ceff883e","includeInDevelopment":false},
    },{
      plugin: require('../../gatsby-plugin-lazy-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PFS4DC","includeInDevelopment":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(external)"},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hipex.io","short_name":"Hipex","start_url":"/?source=pwa","background_color":"rgb(255, 255, 255)","theme_color":"rgb(233, 30, 99)","display":"minimal-ui","icon":"/home/runner/work/hipex-gatsby-site/hipex-gatsby-site/packages/gatsby-theme-hipex/src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"21d86d3548594bb0cd1651939d8e2882"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/hipex-gatsby-site/hipex-gatsby-site/packages/gatsby-theme-hipex/src/cms/cms.js","manualInit":true,"enableIdentityWidget":false},
    },{
      plugin: require('../../gatsby-theme-hipex/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
