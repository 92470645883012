const initEcookie = (id) => {
  const f = document.getElementsByTagName('script')[0];
  const j = document.createElement('script');
  j.async = true;
  j.src = `https://ecookie.nl/build/cookiebar/script/${id}.js`;
  // use `.append`, this one is not blocked by the eCookieBar setup code
  f.parentNode.append(j);

  window.eCookieBar.hook(document.body);
};

// eslint-disable-next-line import/prefer-default-export
export const onInitialClientRender = (_, {
  id,
  includeInDevelopment,
}) => {
  window.prerenderReady = true;

  if (process.env.GATSBY_ENV === 'production' || includeInDevelopment) {
    const load = () => initEcookie(id);

    setTimeout(() => {
      if ('requestIdleCallback' in window) {
        requestIdleCallback(load);
      } else {
        load();
      }
    }, 4000);
  }
};
